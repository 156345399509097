<template>
    <!-- 客户管理》客户信息变更通知 -->
    <div id="app_user_price_search">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
            </div>
            <div class="head_top_title">客户信息变更通知</div>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row id="query_condition_id" style="padding:10px 0;">
                <el-form :model="form_data" :inline="true"  style="margin-bottom: 12px;">
                    
                    <el-form-item label="客户" v-show="form_data.numType != '-1'">
                        <el-input type="textarea" v-model="form_data.cpids" :autosize="{ minRows: 2, maxRows: 10}"
                             placeholder="请填写客户账号" size="mini" >
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="getData()" size="mini">查询</el-button>
                    </el-form-item>
                </el-form>
            </el-row>

            <el-row style="overflow: auto;" :style="{height: ((content_heigth-form_height)+'px')}">
                
                <el-table  :data="tableData" :span-method="objectSpanMethod" :show-header="true" border style="width: 100%" size="mini" >
                    <el-table-column  fixed  prop="company_name" label="公司" min-width="80"  align="center"> </el-table-column>
                    <el-table-column  prop="cpid" label="账号" min-width="80"  align="center"> </el-table-column>
                    <el-table-column  prop="is_mail_notice" label="邮件通知账号单价" min-width="80"  align="center"> </el-table-column>
                    <el-table-column  prop="is_tingting_notice" label="微信发送账号信息" min-width="80"  align="center"> </el-table-column>
                </el-table>

                <el-row type="flex" justify="center" style="margin-top:10px;">
                    <el-button :disabled="tableData.length == 0" type="primary" @click="submit_notice(0)" size="mini">邮件通知账号单价</el-button>
                    <el-button :disabled="tableData.length == 0" type="primary" @click="submit_notice(1)" size="mini">微信发送账号信息</el-button>
                </el-row>
            </el-row>

            

            
            

        </el-row>

        

    </div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import {dateFtt} from '../../api/tool.js';

//import axios from "axios";
export default {

    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度
            form_height:0,//查询条件的高度

            form_data:{
                cpids:"",//客户账号
            },

            tableData:[],//数据
            
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();
    },

    //activated和deactivated配合keep-alive标签使用!
    activated() {
        this.form_data = this.$options.data().form_data;//单个恢复初始化
        this.tableData = this.$options.data().tableData;//单个恢复初始化
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);

            this.$nextTick(function(){
                //获取查询条件的高度
                if(document.getElementById('query_condition_id')){
                    this.form_height = document.getElementById('query_condition_id').clientHeight;
                }
            });

        },

        //查询
        getData(){
            this.$nextTick(function(){
                //获取查询条件的高度
                if(document.getElementById('query_condition_id')){
                    this.form_height = document.getElementById('query_condition_id').clientHeight;
                }
            });

            if(API.isEmtry(this.form_data.cpids)){
                this.$message.error("请填写客户账号");
                return;
            }
            //请求接口
            API.UserServlet({
                param: "getUserInfoNotice",
                cpids:this.form_data.cpids,
            }).then((res) => {
                if (res.recode === 0) {
                    
                    var list = res.list;
                    var listNew = [];
                    for(var i=0;i<list.length;i++){
                        var obj = list[i];
                        var company_name = obj.company_name;
                        var is_tingting_notice = obj.is_tingting_notice;
                        var is_mail_notice = obj.is_mail_notice;
                        var cpid_list = obj.cpid_list;
                        for(var j=0;j<cpid_list.length;j++){
                            var obj2 = cpid_list[j];

                            obj2.company_name = company_name;
                            obj2.is_tingting_notice = is_tingting_notice == 0 ? '通知' : '不通知';
                            obj2.is_mail_notice = is_mail_notice == 0 ? '通知' : '不通知';
                            if(j==0){
                                obj2.row_num = cpid_list.length;
                            }else{
                                obj2.row_num = 0;
                            }
                            listNew.push(obj2);
                        }
                    }
                    console.log(listNew.toString());
                    this.tableData = listNew;

                } 
            });
        },

        //table表格 合并行或列的计算方法
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            console.log("我："+row.row_num);
            if(columnIndex == 0 || columnIndex == 2 || columnIndex == 3){
                if(row.row_num > 1){
                    return {rowspan: row.row_num, colspan: 1};
                }else if(row.row_num == 1){
                    return {rowspan: 1, colspan: 1};
                }else{
                    return {rowspan: 0,colspan: 0 };
                }
            }
        },

        //通知提交,type值0：邮件通知账号单价；1：微信发送账号信息
        submit_notice(type){
            if(API.isEmtry(this.form_data.cpids)){
                this.$message.error("请填写客户账号");
                return;
            }
            var param = "";
            if(type == 0){
                param = "noticeUserInfoPrice";
            }else{
                param = "noticeUserInfo";
            }
            //请求接口
            API.UserServlet({
                param: param,
                cpids:this.form_data.cpids,
            }).then((res) => {
                if (res.recode === 0) {
                    this.$message.success("恭喜您，通知信息已经提交成功");
                } 
            });
        },
        
        //返回
        goBack() {
            API.router_to("/user_index");
        }
    }
};

</script>

<style scoped>

</style>